<template>
  <div>
    <aside>
      <div class="w-box mb-20">
        <PackageMetaSection
          :productForm="productForm"
          :tempProduct="tempProduct"
        />
      </div>

      <div
        v-if="['edit', 'products-budget-edit'].includes($route.name)"
        class="w-box mb-20"
      >
        <PackageSidebarExtraSection
          :productForm="productForm"
          :tempProduct="tempProduct"
        />
      </div>

      <div class="w-box mb-20">
        <PackageSaveButtonSection
          :productForm="productForm"
          :tempProduct="tempProduct"
          :loadingSubmitProduct="loadingSubmitProduct"
          @onClickPublishProduct="onClickPublishProduct"
        />
      </div>
    </aside>
  </div>
</template>

<script>
import PackageMetaSection from "./PackageMetaSection.vue";
import PackageSaveButtonSection from "./PackageSaveButtonSection.vue";
import PackageSidebarExtraSection from "./PackageSidebarExtraSection.vue";

export default {
  name: "PackageSidebarSection",
  props: {
    productForm: Object,
    tempProduct: Object,
    loadingSubmitProduct: Boolean,
  },
  components: {
    PackageMetaSection,
    PackageSaveButtonSection,
    PackageSidebarExtraSection,
  },
  methods: {
    onClickPublishProduct(status) {
      console.log(status);
      this.$emit("onClickPublishProduct", status);
    },
  },
};
</script>
