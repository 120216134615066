<template>
  <div class="buttons">
    <aRow
      v-if="['edit', 'products-budget-edit'].includes($route.name)"
      :gutter="15"
    >
      <aCol :span="18">
        <a-button
          v-if="tempProduct.status === 'draft'"
          class="button-green"
          size="large"
          type="primary"
          block
          :disabled="
            tempProduct.name && tempProduct.type && tempProduct.image
              ? false
              : true
          "
          :loading="loadingSubmitProduct"
          @click="onClickPublishProduct"
        >
          PUBLICAR
        </a-button>

        <a-button
          v-if="tempProduct.status === 'published'"
          size="large"
          class="f16"
          block
          :disabled="false"
          @click="packageButtonAction"
        >
          <a-icon type="eye" /> VER DETALHES
        </a-button>
      </aCol>

      <aCol :span="6">
        <a-button
          class="w100"
          type="primary"
          size="large"
          html-type="submit"
          block
          :loading="loadingSubmitProduct"
        >
          <img
            v-if="!loadingSubmitProduct"
            src="@/assets/images/dashboard/contracts/save.png"
            width="22"
          />
        </a-button>
      </aCol>
    </aRow>

    <aRow v-if="$route.name === 'new'" :gutter="15">
      <aCol :span="24">
        <a-button
          class="w100"
          type="primary"
          size="large"
          html-type="submit"
          block
          :loading="loadingSubmitProduct"
        >
          CADASTRAR
        </a-button>
      </aCol>
    </aRow>
  </div>
</template>

<script>
export default {
  name: "PackageSaveButtonSection",
  props: {
    productForm: Object,
    tempProduct: Object,
    loadingSubmitProduct: Boolean,
  },
  methods: {
    packageButtonAction() {
      let type = "products";
      if (this.$route.name === "products-budget-edit") type = "products-budget";
      this.$router.push(`/${type}/view/${this.tempProduct.id}`);
    },
    onClickPublishProduct() {
      this.$emit("onClickPublishProduct", "published");
    },
  },
};
</script>

<style lang="sass" scoped>
.buttons
  button
    padding-top: 15px
    padding-bottom: 15px
    padding: 0
    height: 59px
    font-size: 20px
    width: 100% !important
    &:disabled
      pointer-events: none
      background: #f6f6f6
      opacity: 0.7
      border-color: #bbb !important
  .button-green
    background: #2ecc71
    border-color: #2ecc71
    font-weight: 600
</style>
