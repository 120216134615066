<template>
  <div class="meta">
    <aRow class="title" type="flex" justify="space-between">
      <aCol v-if="$route.name !== 'new'">
        <h1>
          <img
            src="@/assets/images/dashboard/products/products-img.png"
            alt="img"
            width="30"
          />
          <font v-if="$route.name === 'products-budget-edit'"
            >ORÇAMENTO #{{ tempProduct.id }}</font
          >
          <font v-if="$route.name === 'edit'"
            >PRODUTO #{{ tempProduct.id }}</font
          >
        </h1>
      </aCol>

      <aCol v-if="$route.name === 'new'">
        <h1>
          <img src="@/assets/images/agreement.png" alt="img" />
          NOVO PRODUTO
        </h1>
      </aCol>
    </aRow>

    <aRow class="extra" align="middle" type="flex" justify="start">
      <aCol>
        <UserAvatar :user="tempProduct.user" :avatarSize="20" />
      </aCol>
      <a-col v-if="$route.name !== 'new'">
        <a-divider type="vertical" />
        <a-icon class="cprimary" type="calendar" />
        {{ tempProduct.created | formatDateTime }}
      </a-col>
    </aRow>
  </div>
</template>

<script>
import UserAvatar from "@/components/general/elements/UserAvatar.vue";
import reusableThings from "@/mixins/general/reusableThings";

export default {
  name: "PackageMetaSection",
  props: {
    productForm: Object,
    tempProduct: Object,
  },
  mixins: [reusableThings],
  components: { UserAvatar },
};
</script>

<style lang="sass" scoped>
.meta
  .title
    h1
      margin: 0
      padding: 0
      font-weight: 600
      letter-spacing: -2px
      line-height: 1
      img
          margin-right: 5px
  .extra
    font-size: 10px
    line-height: 8px
    font-weight: 600
    text-transform: none
    padding-top: 10px
    border-top: 1px solid #eee
    margin-top: 4px
</style>
