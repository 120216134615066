<template>
  <div>
    <a
      class="user dotted-phrase"
      @click="$router.push(`/users/view/${user.id}`)"
      v-if="user != undefined"
      target="_blank"
      style=""
    >
      <aTooltip placement="right">
        <template #title>
          {{ user.first_name }}
          {{ user.last_name }}
        </template>
        <a-avatar v-if="user.avatar" :src="user.avatar" :size="avatarSize" />
        <a-avatar
          v-else
          :size="avatarSize"
          class="upper"
          style="color: #f56a00; background-color: #fde3cf"
        >
          {{ user.first_name.substring(0, 1)
          }}{{ user.last_name.substring(0, 1) }}
        </a-avatar>
      </aTooltip>
    </a>
  </div>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    user: Object,
    avatarSize: Number,
  },
};
</script>

<style lang="scss" scoped></style>
