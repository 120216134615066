<template>
  <div class="extra">
    <aRow type="flex" justify="space-between">
      <aCol :span="24">
        <label class="label"> Imagem do produto </label>

        <a-upload
          class="image-upload"
          name="file"
          list-type="picture-card"
          :show-upload-list="false"
          :action="`https://api.viajar.tur.br/v1/media/upload-to-s3?module=products&id=${tempProduct.id}`"
          :headers="{
            authorization: `Bearer ${this.$store.state.token}`,
          }"
          @change="uploadedFile"
          v-model="tempProduct.image"
        >
          <img
            v-if="tempProduct.image"
            :src="tempProduct.image"
            style="max-width: 296px"
          />
          <div v-else>
            <a-icon :type="loadingImage ? 'loading' : 'plus'" />
          </div>
        </a-upload>

        <div v-if="tempProduct.image" class="a-center">
          <a class="red f10" @click="tempProduct.image = ''">
            <a-icon type="delete" /> remover imagem</a
          >
        </div>
      </aCol>
    </aRow>
  </div>
</template>

<script>
import reusableThings from "@/mixins/general/reusableThings";

export default {
  name: "PackageSidebarExtraSection",
  props: {
    productForm: Object,
    tempProduct: Object,
  },
  mixins: [reusableThings],
  data() {
    return {
      loadingImage: false,
    };
  },
  methods: {
    uploadedFile(response) {
      if (response.file?.response?.url !== undefined)
        this.tempProduct.image = response.file.response.url;
    },
  },
};
</script>

<style lang="sass">
.image-upload
  .ant-upload-select-picture-card
    width: 314px
    height: 236px
    background-color: #fafafa
    border: 1px dashed #d9d9d9
</style>

<style lang="sass" scoped>
.label
  z-index: 4
  font-size: 10px
  font-weight: 500
  color: #ccc
  line-height: 1
  width: 100%
  margin-bottom: 10px
  display: block
</style>